.chatits {

  .chat {

    &__message {
      .chat {
        &__message {
          &--content {

            .chat {
              &__message {
                &--message {
                  background-color: var(--chakra-colors-gray-400);
                  &:before {
                    border-color: transparent transparent var(--chakra-colors-gray-400) transparent;
                  }
                }
              }
            }

          }

          &--date {
            color: seagreen;
          }
        }
      }
      &--outgoing {

        .chat {
          &__message {
            &--content {

              .chat {
                &__message {
                  &--message {
                    background-color: seagreen;
                    &:before {
                      border-color: transparent transparent seagreen transparent;
                    }
                  }
                }
              }

            }
          }
        }
      }
    }
  }
}