.chatits {

  .chat {
    &__modal {
      &--content {
        .chat {
          &__modal {
            &--header {
              background: var(--chakra-colors-gray-500);
              color: white;
            }
            &--close {
              svg {
                color: black !important;
              }
            }
            &--body {
              background: white;

              .chat {
                &__input {
                  input {
                    color: black !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}