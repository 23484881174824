@import "variables";

.bg {
  &--transparent {
    background: transparent;
  }
  &--white {
    background: white;
  }
  &--grey {
    background: grey;
  }
  &--main {
    background: $mainColor !important;
  }
  &--secondary {
    background: $secondaryColor;
  }
  &--main-bg {
    background: $mainBgColor;
  }
  &--secondary-bg {
    background: $secondaryBgColor;
  }
}

.color {
  &--transparent {
    color: transparent;
  }
  &--white {
    color: white;
  }
  &--grey {
    color: grey;
  }
  &--main {
    color: $mainColor;
  }
  &--secondary {
    color: $secondaryColor;
  }
  &--main-bg {
    color: $mainBgColor;
  }
  &--secondary-bg {
    color: $secondaryBgColor;
  }
}

.border {
  &--white {
    border: 1px solid white !important;
  }
  &--grey {
    border: 1px solid grey !important;
  }
  &--main {
    border: 2px solid $mainColor !important;
  }
  &--secondary {
    border: 1px solid $secondaryColor;
  }
  &--main-bg {
    border: 1px solid $mainBgColor;
  }
  &--secondary-bg {
    border: 1px solid $secondaryBgColor;
  }
}