.chatits {

  &--wrapper {
    .chat {
      &__button {
        background-color: seagreen;
        box-shadow: 0px 4px 10px grey;
        width: 60px;
        height: 60px;

        &:hover {
          background-color: mediumseagreen;
        }

        svg {
          width: 25px;
          height: 25px;
          color: white !important;
          stroke: white !important;
          fill: white !important;
        }

        &__wrapper {

          .number-of-unread {
            background: red !important;
          }
        }

        &--minimize {
          top: -20px;
        }
      }
      &__channel {

        .chat {
          &__button {
            background: transparent !important;
            display: flex;
            align-items: center;
            justify-content: center;

            &:hover {
              background: mediumseagreen !important;
              svg {
                scale: 1;
              }
            }


          }
        }
      }
    }


  }

  .chat {

    &__button {
      background-color: mediumseagreen;
      width: 45px;
      height: 45px;
      box-shadow: none;

      &:hover {
        background-color: seagreen;
      }
      svg {
        width: 20px;
        height: 20px;
        color: white !important;
        stroke: white !important;
        fill: white !important;
      }
    }

    &__input {

      &--container {
        color: seagreen;
      }

      input {
        background: transparent;
        border-color: var(--chakra-colors-gray-400);
        color: black !important;
      }

      button {
        background-color: seagreen;
      }
    }

    &__search {
      &--input {
        input {
          background: var(--chakra-colors-blue-400);
          color: white;
        }
      }
    }

    &__addChannelButton {
      background-color: mediumseagreen;
      border-color: seagreen;
      &:hover {
        background-color: seagreen;
      }
    }
  }
}