.chatits {
  &--wrapper {
    background: transparent;
    z-index: 2;
  }

  .chat {
    color: seagreen;

    &__column {
      background-color: var(--chakra-colors-gray-100);
      color: var(--chakra-colors-gray-800);
      box-shadow: 0px 4px 10px grey;
      width: 320px;
      padding-top: 30px;

      &--footer {
        background: var(--chakra-colors-gray-100);
        color: var(--chakra-colors-gray-800);
        box-shadow: 0px 4px 10px grey;

        .chat {
          &__channel {
            &:hover {
              background: var(--chakra-colors-gray-100);
              scale: none;
            }
          }
        }
      }
    }

    &__channel {
      &--name {
        color: black;
      }
      &--date {
        color: seagreen;
      }
    }

    &__main {
      background: white;
      box-shadow: 0px 4px 10px grey;

      &--header {
        background:var(--chakra-colors-gray-600);
      }
    }

    &__search {
      &--icon {
        svg {
          color: white !important;
          stroke: white !important;
          fill: white !important;
        }

      }
    }
  }
}

@import "scss/message";
@import "scss/definitions";
@import "scss/inputs";
@import "scss/modal";

